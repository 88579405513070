<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="EN Title"
            rules="required"
          >
            <b-form-group
              label="EN Title"
              label-for="title_en"
            >
              <b-form-input
                id="title_en"
                v-model="articleForm.title_en"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="EN Title"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="AR Title"
            rules="required"
          >
            <b-form-group
              label="AR Title"
              label-for="title_ar"
            >
              <b-form-input
                id="title_ar"
                v-model="articleForm.title_ar"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="AR Title"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Content -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="AR Content"
          >
            <b-form-group
              label="EN Content"
              label-for="content_en"
            >
              <quill-editor
                ref="myQuillEditor"
                v-model="articleForm.content_en"
                :options="editorOption"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

        </b-col>
        <b-col md="6">
          <!-- Content -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="AR Content"
          >
            <b-form-group
              label="AR Content"
              label-for="content_ar"
            >
              <quill-editor
                ref="myQuillEditor"
                v-model="articleForm.content_ar"
                :options="editorOption"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="6">
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  v-img
                  rounded
                  :src="articleForm.image"
                  height="100"
                  width="100"
                />
              </b-link>
              <!--/ image -->
            </b-media-aside>

            <b-media-body>
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectImg()"
              >
                Upload Image
              </b-button>
              <b-form-file
                ref="refImg"
                accept=".jpg, .png, .jpeg"
                :hidden="true"
                plain
                @change="changeImage($event)"
              />
              <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>
        <b-col md="6">
          <!-- position -->
          <validation-provider
            #default="validationContext"
            name="tags"
            rules="required"
          >
            <b-form-group
              label="tags"
              label-for="tags"
            >
              <b-form-input
                id="tags"
                v-model="articleForm.tags"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="tags"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addArticle()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addArticle()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>

      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      required,
      id: this.$store.state.generalIds.id,
      loader: false,
      file: '',
      image: '',
      en_image: '',
      errorMsg: '',
      errors: {},
      editorOption: {
        placeholder: 'Enter Text',
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            ['clean'],
          ],
        },
      },
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const articleForm = ref({
      image: null,

    })
    // eslint-disable-next-line camelcase
    const file = ref('')

    return {
      getValidationState,
      articleForm,
      file,
    }
  },
  mounted() {
    this.showArticle()
  },
  methods: {

    selectImg() {
      this.$refs.refImg.$el.click()
    },

    changeImage(e) {
      // eslint-disable-next-line prefer-destructuring
      this.image = e.target.files[0]
      const input = e.target
      const img = new Image()
      img.src = window.URL.createObjectURL(e.target.files[0])
      img.onload = () => {
        if (input.files) {
          const reader = new FileReader()
          reader.onload = er => {
            this.articleForm.image = er.target.result
          }

          // eslint-disable-next-line prefer-destructuring
          this.image = input.files[0]
          reader.readAsDataURL(input.files[0])
        }
      }
    },

    showArticle() {
      if (this.$route.params.id) {
        axios.get(`admin/articles/${this.$route.params.id}`).then(res => {
          this.articleForm = res.data.data
        })
      } else {
        return false
      }
      return true
    },

    addArticle() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.articleForm) {
          if (key !== 'image') {
            formData.append(key, this.articleForm[key])
          }
        }
        formData.delete('image')
        if (this.image) {
          formData.append('image', this.image)
        }
        axios.post(`admin/articles/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'articles' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 400) {
            this.errorMsg = error.response.data.error
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.articleForm) {
          if (key !== 'image') {
            formData.append(key, this.articleForm[key])
          }
        }
        formData.delete('image')
        if (this.image) {
          formData.append('image', this.image)
        }

        this.loader = true
        axios.post('admin/articles', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'articles' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 400) {
            this.errorMsg = error.response.data.error
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
